// source: Wilqo.API.Mortgage.Integration/Models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('../Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_Models_UIComponents_UxDisplayStatusEnum_pb = require('../Wilqo.Shared.Models/UIComponents/UxDisplayStatusEnum_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_UIComponents_UxDisplayStatusEnum_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.AccountData', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.AccountData.AccountDetail', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.ComplianceCheckType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.DocMagicRequestType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.DocumentVendor', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.IntegrationHistory', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.IntegrationTypeLocalEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.IntegrationVendorEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.SimpleOption', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.StepInfo', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.StepStatus', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.UxDisplayBadge', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.UxDisplayBadge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.UxDisplayBadge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.UxDisplayBadge.displayName = 'proto.wilqo.api.mortgage_integration.UxDisplayBadge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.IntegrationHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.IntegrationHistory.displayName = 'proto.wilqo.api.mortgage_integration.IntegrationHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.StepInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.StepInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.StepInfo.displayName = 'proto.wilqo.api.mortgage_integration.StepInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.SimpleOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.SimpleOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.SimpleOption.displayName = 'proto.wilqo.api.mortgage_integration.SimpleOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance.displayName = 'proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures.displayName = 'proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.AccountData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.AccountData.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.AccountData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.AccountData.displayName = 'proto.wilqo.api.mortgage_integration.AccountData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.AccountData.AccountDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.displayName = 'proto.wilqo.api.mortgage_integration.AccountData.AccountDetail';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.UxDisplayBadge.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.UxDisplayBadge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.UxDisplayBadge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.UxDisplayBadge.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.UxDisplayBadge}
 */
proto.wilqo.api.mortgage_integration.UxDisplayBadge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.UxDisplayBadge;
  return proto.wilqo.api.mortgage_integration.UxDisplayBadge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.UxDisplayBadge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.UxDisplayBadge}
 */
proto.wilqo.api.mortgage_integration.UxDisplayBadge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.shared.models.UxDisplayStatusEnum} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.UxDisplayBadge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.UxDisplayBadge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.UxDisplayBadge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.UxDisplayBadge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.UxDisplayBadge.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.UxDisplayBadge} returns this
 */
proto.wilqo.api.mortgage_integration.UxDisplayBadge.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.UxDisplayStatusEnum status = 2;
 * @return {!proto.wilqo.shared.models.UxDisplayStatusEnum}
 */
proto.wilqo.api.mortgage_integration.UxDisplayBadge.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.shared.models.UxDisplayStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.shared.models.UxDisplayStatusEnum} value
 * @return {!proto.wilqo.api.mortgage_integration.UxDisplayBadge} returns this
 */
proto.wilqo.api.mortgage_integration.UxDisplayBadge.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.IntegrationHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.IntegrationHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    integrationName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdDate: (f = msg.getCreatedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    badge: (f = msg.getBadge()) && proto.wilqo.api.mortgage_integration.UxDisplayBadge.toObject(includeInstance, f),
    lastStatusDate: (f = msg.getLastStatusDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.IntegrationHistory}
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.IntegrationHistory;
  return proto.wilqo.api.mortgage_integration.IntegrationHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.IntegrationHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.IntegrationHistory}
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntegrationName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new proto.wilqo.api.mortgage_integration.UxDisplayBadge;
      reader.readMessage(value,proto.wilqo.api.mortgage_integration.UxDisplayBadge.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastStatusDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.IntegrationHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.IntegrationHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntegrationName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.wilqo.api.mortgage_integration.UxDisplayBadge.serializeBinaryToWriter
    );
  }
  f = message.getLastStatusDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string track_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.IntegrationHistory} returns this
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string integration_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.getIntegrationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.IntegrationHistory} returns this
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.setIntegrationName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.IntegrationHistory} returns this
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.getCreatedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.IntegrationHistory} returns this
*/
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.setCreatedDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.IntegrationHistory} returns this
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.clearCreatedDate = function() {
  return this.setCreatedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.hasCreatedDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.IntegrationHistory} returns this
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional UxDisplayBadge badge = 6;
 * @return {?proto.wilqo.api.mortgage_integration.UxDisplayBadge}
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.getBadge = function() {
  return /** @type{?proto.wilqo.api.mortgage_integration.UxDisplayBadge} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_integration.UxDisplayBadge, 6));
};


/**
 * @param {?proto.wilqo.api.mortgage_integration.UxDisplayBadge|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.IntegrationHistory} returns this
*/
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.IntegrationHistory} returns this
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp last_status_date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.getLastStatusDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.IntegrationHistory} returns this
*/
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.setLastStatusDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.IntegrationHistory} returns this
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.clearLastStatusDate = function() {
  return this.setLastStatusDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.IntegrationHistory.prototype.hasLastStatusDate = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.StepInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.StepInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.StepInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inputName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hasOutput: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isPassThrough: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    duration: jspb.Message.getFieldWithDefault(msg, 7, 0),
    stepError: (f = msg.getStepError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.StepInfo}
 */
proto.wilqo.api.mortgage_integration.StepInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.StepInfo;
  return proto.wilqo.api.mortgage_integration.StepInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.StepInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.StepInfo}
 */
proto.wilqo.api.mortgage_integration.StepInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInputName(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage_integration.StepStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasOutput(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPassThrough(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setStepError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.StepInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.StepInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.StepInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInputName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getHasOutput();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsPassThrough();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getStepError();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.StepInfo} returns this
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string input_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.getInputName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.StepInfo} returns this
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.setInputName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional StepStatus status = 3;
 * @return {!proto.wilqo.api.mortgage_integration.StepStatus}
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage_integration.StepStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.StepStatus} value
 * @return {!proto.wilqo.api.mortgage_integration.StepInfo} returns this
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool has_output = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.getHasOutput = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_integration.StepInfo} returns this
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.setHasOutput = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_pass_through = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.getIsPassThrough = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_integration.StepInfo} returns this
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.setIsPassThrough = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp start = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.StepInfo} returns this
*/
proto.wilqo.api.mortgage_integration.StepInfo.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.StepInfo} returns this
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.hasStart = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 duration = 7;
 * @return {number}
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_integration.StepInfo} returns this
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional wilqo.messaging.WilqoError step_error = 8;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.getStepError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 8));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.StepInfo} returns this
*/
proto.wilqo.api.mortgage_integration.StepInfo.prototype.setStepError = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.StepInfo} returns this
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.clearStepError = function() {
  return this.setStepError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.StepInfo.prototype.hasStepError = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.SimpleOption.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.SimpleOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.SimpleOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.SimpleOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    selected: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.SimpleOption}
 */
proto.wilqo.api.mortgage_integration.SimpleOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.SimpleOption;
  return proto.wilqo.api.mortgage_integration.SimpleOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.SimpleOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.SimpleOption}
 */
proto.wilqo.api.mortgage_integration.SimpleOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.SimpleOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.SimpleOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.SimpleOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.SimpleOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSelected();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.SimpleOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.SimpleOption} returns this
 */
proto.wilqo.api.mortgage_integration.SimpleOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.SimpleOption.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.SimpleOption} returns this
 */
proto.wilqo.api.mortgage_integration.SimpleOption.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool selected = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.SimpleOption.prototype.getSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_integration.SimpleOption} returns this
 */
proto.wilqo.api.mortgage_integration.SimpleOption.prototype.setSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance.toObject = function(includeInstance, msg) {
  var f, obj = {
    complianceCheckTypesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance}
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance;
  return proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance}
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage_integration.ComplianceCheckType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addComplianceCheckTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComplianceCheckTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated ComplianceCheckType compliance_check_types = 1;
 * @return {!Array<!proto.wilqo.api.mortgage_integration.ComplianceCheckType>}
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance.prototype.getComplianceCheckTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage_integration.ComplianceCheckType>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_integration.ComplianceCheckType>} value
 * @return {!proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance} returns this
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance.prototype.setComplianceCheckTypesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.ComplianceCheckType} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance} returns this
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance.prototype.addComplianceCheckTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance} returns this
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance.prototype.clearComplianceCheckTypesList = function() {
  return this.setComplianceCheckTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures.toObject = function(includeInstance, msg) {
  var f, obj = {
    disclosuresPackageType: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures}
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures;
  return proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures}
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisclosuresPackageType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisclosuresPackageType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string disclosures_package_type = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures.prototype.getDisclosuresPackageType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures} returns this
 */
proto.wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationDisclosures.prototype.setDisclosuresPackageType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.AccountData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.AccountData.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.AccountData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.AccountData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.AccountData.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountDetailsList: jspb.Message.toObjectList(msg.getAccountDetailsList(),
    proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.AccountData}
 */
proto.wilqo.api.mortgage_integration.AccountData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.AccountData;
  return proto.wilqo.api.mortgage_integration.AccountData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.AccountData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.AccountData}
 */
proto.wilqo.api.mortgage_integration.AccountData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_integration.AccountData.AccountDetail;
      reader.readMessage(value,proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.deserializeBinaryFromReader);
      msg.addAccountDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.AccountData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.AccountData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.AccountData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.AccountData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    businessChannelId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessChannelName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    originatorId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    originatorUsername: jspb.Message.getFieldWithDefault(msg, 6, ""),
    originatorFirstName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    originatorLastName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    originatorAccessLevel: jspb.Message.getFieldWithDefault(msg, 9, ""),
    originatorPhoneNumber: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail}
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.AccountData.AccountDetail;
  return proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail}
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBusinessChannelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessChannelName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOriginatorId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginatorUsername(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginatorFirstName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginatorLastName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginatorAccessLevel(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginatorPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBusinessChannelId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessChannelName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOriginatorId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getOriginatorUsername();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOriginatorFirstName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOriginatorLastName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOriginatorAccessLevel();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOriginatorPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail} returns this
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 business_channel_id = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.getBusinessChannelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail} returns this
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.setBusinessChannelId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail} returns this
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_channel_name = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.getBusinessChannelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail} returns this
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.setBusinessChannelName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 originator_id = 5;
 * @return {number}
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.getOriginatorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail} returns this
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.setOriginatorId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string originator_username = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.getOriginatorUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail} returns this
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.setOriginatorUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string originator_first_name = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.getOriginatorFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail} returns this
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.setOriginatorFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string originator_last_name = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.getOriginatorLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail} returns this
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.setOriginatorLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string originator_access_level = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.getOriginatorAccessLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail} returns this
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.setOriginatorAccessLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string originator_phone_number = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.getOriginatorPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail} returns this
 */
proto.wilqo.api.mortgage_integration.AccountData.AccountDetail.prototype.setOriginatorPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.AccountData.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.AccountData} returns this
 */
proto.wilqo.api.mortgage_integration.AccountData.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated AccountDetail account_details = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail>}
 */
proto.wilqo.api.mortgage_integration.AccountData.prototype.getAccountDetailsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_integration.AccountData.AccountDetail, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail>} value
 * @return {!proto.wilqo.api.mortgage_integration.AccountData} returns this
*/
proto.wilqo.api.mortgage_integration.AccountData.prototype.setAccountDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.AccountData.AccountDetail}
 */
proto.wilqo.api.mortgage_integration.AccountData.prototype.addAccountDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_integration.AccountData.AccountDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.AccountData} returns this
 */
proto.wilqo.api.mortgage_integration.AccountData.prototype.clearAccountDetailsList = function() {
  return this.setAccountDetailsList([]);
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.StepStatus = {
  STEP_STATUS_UNKNOWN: 0,
  STEP_STATUS_READY: 1,
  STEP_STATUS_IN_PROGRESS: 2,
  STEP_STATUS_PAUSED: 3,
  STEP_STATUS_SUCCEEDED: 4,
  STEP_STATUS_FAILED: 5
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.DocumentVendor = {
  DOCUMENT_VENDOR_UNKNOWN: 0,
  DOCUMENT_VENDOR_ASURITY: 1,
  DOCUMENT_VENDOR_DOCUTECH: 2,
  DOCUMENT_VENDOR_DOC_MAGIC: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.IntegrationTypeLocalEnum = {
  INTEGRATION_TYPE_LOCAL_ENUM_UNKNOWN: 0,
  INTEGRATION_TYPE_LOCAL_ENUM_CREDIT: 1,
  INTEGRATION_TYPE_LOCAL_ENUM_FRAUD: 2,
  INTEGRATION_TYPE_LOCAL_ENUM_FLOOD: 3,
  INTEGRATION_TYPE_LOCAL_ENUM_DISCLOSURES: 4,
  INTEGRATION_TYPE_LOCAL_ENUM_COMPLIANCE: 5,
  INTEGRATION_TYPE_LOCAL_ENUM_FEES: 6,
  INTEGRATION_TYPE_LOCAL_ENUM_AUS: 7,
  INTEGRATION_TYPE_LOCAL_ENUM_APPRAISAL: 8,
  INTEGRATION_TYPE_LOCAL_ENUM_PRODUCT_AND_PRICING: 9,
  INTEGRATION_TYPE_LOCAL_ENUM_DOCUMENT_GENERATION: 11,
  INTEGRATION_TYPE_LOCAL_ENUM_MERS_REGISTRATION: 12,
  INTEGRATION_TYPE_LOCAL_ENUM_MORTGAGE_INSURANCE: 13
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.IntegrationVendorEnum = {
  INTEGRATION_VENDOR_ENUM_UNKNOWN: 0,
  INTEGRATION_VENDOR_ENUM_FACTUAL: 1,
  INTEGRATION_VENDOR_ENUM_ARCHMORTGAGEINSURANCE: 2,
  INTEGRATION_VENDOR_ENUM_DESKTOPUNDERWRITER: 3,
  INTEGRATION_VENDOR_ENUM_OPTIMAL_BLUE: 4,
  INTEGRATION_VENDOR_ENUM_POLLY: 5,
  INTEGRATION_VENDOR_ENUM_DOCUTECH: 6,
  INTEGRATION_VENDOR_ENUM_REGGORA: 7,
  INTEGRATION_VENDOR_ENUM_XACTUS: 8,
  INTEGRATION_VENDOR_ENUM_LOAN_PASS: 9,
  INTEGRATION_VENDOR_ENUM_MERS: 10,
  INTEGRATION_VENDOR_ENUM_DOC_MAGIC: 11,
  INTEGRATION_VENDOR_ENUM_ASURITY: 12
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.ComplianceCheckType = {
  COMPLIANCE_CHECK_UNSPECIFIED: 0,
  COMPLIANCE_CHECK_HOEPA: 1,
  COMPLIANCE_CHECK_NMLS: 2,
  COMPLIANCE_CHECK_TRID: 3,
  COMPLIANCE_CHECK_STATE_HIGH_COST: 4,
  COMPLIANCE_CHECK_FEDERAL_HPM: 5,
  COMPLIANCE_CHECK_TILA: 6,
  COMPLIANCE_CHECK_QUALIFIED_MORTGAGE: 7,
  COMPLIANCE_CHECK_TEXAS_HOME_EQUITY: 8,
  COMPLIANCE_CHECK_TEXAS50F2: 9,
  COMPLIANCE_CHECK_NEW_YORK_SUB_PRIME: 10,
  COMPLIANCE_CHECK_CALIFORNIA_HPML: 11,
  COMPLIANCE_CHECK_RESPA: 12,
  COMPLIANCE_CHECK_STATE_SPECIFIC: 13,
  COMPLIANCE_CHECK_USDA: 14,
  COMPLIANCE_CHECK_FHA: 15,
  COMPLIANCE_CHECK_VA: 16,
  COMPLIANCE_CHECK_FREDDIE_MAC: 17,
  COMPLIANCE_CHECK_FANNIE_MAE: 18,
  COMPLIANCE_CHECK_FLOOD_INSURANCE: 19,
  COMPLIANCE_CHECK_COOK_COUNTY: 20,
  COMPLIANCE_CHECK_OFAC: 21,
  COMPLIANCE_CHECK_CONNECTICUT_NON_PRIME: 22,
  COMPLIANCE_CHECK_CHICAGO: 23,
  COMPLIANCE_CHECK_ALL: 24,
  COMPLIANCE_CHECK_CLEVELAND_HEIGHTS: 25,
  COMPLIANCE_CHECK_KENTON: 26
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.DocMagicRequestType = {
  DOC_MAGIC_REQUEST_TYPE_UNKNOWN: 0,
  DOC_MAGIC_REQUEST_TYPE_GENERATE_DOCUMENTS: 1,
  DOC_MAGIC_REQUEST_TYPE_AUDIT: 2
};

goog.object.extend(exports, proto.wilqo.api.mortgage_integration);
