import { Disclosure } from '@headlessui/react';
import { useCallback, useEffect, useState } from 'react';

import { UxDisplayStatusEnum } from '@/API/Models/Wilqo_API_Brand_Models_pb';
import type {
  LoanEscrowFee,
  LoanFeeCategoryTypeEnum,
  LoanPrepaidFee,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { LoanFeeSpecialCaseEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { Badge } from '@/Components/Atoms/Badge';
import { Button } from '@/Components/Atoms/Button';
import { Icon } from '@/Components/Atoms/Icon';
import { Input } from '@/Components/Atoms/Input';
import { MaskType } from '@/Components/Atoms/Input/Input.component';
import { Select } from '@/Components/Atoms/Select';
import { Toggle } from '@/Components/Atoms/Toggle';
import { Tooltip } from '@/Components/Atoms/Tooltip';
import { Typography } from '@/Components/Atoms/Typography';
import { useFeesContext } from '@/Routes/Pages/loan/PurposeBuilt/Fees/FeesContext';
import { pluralizeDays, pluralizeMonths } from '@/Utils/Helpers/dateHelpers';
import { convertProtoToCurrency } from '@/Utils/Helpers/numberFormatter';
import { FeePermissions } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';

export interface IOverrideProps {
  viewOnly?: boolean;
  fee: LoanEscrowFee.AsObject | LoanPrepaidFee.AsObject;
  onEditFee?: (fee: LoanEscrowFee.AsObject | LoanPrepaidFee.AsObject, categoryType: LoanFeeCategoryTypeEnum) => void;
  categoryType: LoanFeeCategoryTypeEnum;
  feePermissions : FeePermissions.AsObject;
  handleTimeframeOverride?: (value: string | undefined, controlName: string, fee: LoanEscrowFee.AsObject | LoanPrepaidFee.AsObject) => void;
  handleTotalOverride?: (value: string | undefined, controlName: string, fee: LoanEscrowFee.AsObject | LoanPrepaidFee.AsObject, feePermissions: FeePermissions.AsObject) => void;
  handleIndicatorOverride?: (value: boolean, controlName: string, fee: LoanEscrowFee.AsObject | LoanPrepaidFee.AsObject) => void;
  handleClear?: () => void;
  id: string;
}

interface IValues {
  label: string;
  value: string | undefined;
}

const daysArray: Array<IValues> = [];

const monthsArray: Array<IValues> = [];

for (let i = 1; i <= 31; i += 1) {
  const value = i.toString();
  daysArray.push({ label: value, value });
}

for (let i = 1; i <= 12; i += 1) {
  const value = i.toString();
  monthsArray.push({ label: value, value });
}

const FeeOverrideControls = (props: IOverrideProps) => {
  const { categoryType, fee, handleClear, handleIndicatorOverride, handleTimeframeOverride, handleTotalOverride, id, onEditFee, viewOnly = true, feePermissions } = props;
  const { overrideErrors, setOverrideErrors } = useFeesContext();
  const uniqueKey = `uniqueKey-${fee.feeId}-${fee.name}`;

  const {
    calculatedTimeframe,
    calculatedTotalAmount,
    isApr,
    manualOverrideIndicator,
    manualOverrideTimeframe,
    manualOverrideTotalAmount,
    name,
    specialCase,
  } = fee;

  const specialCaseTypeMonths = specialCase === LoanFeeSpecialCaseEnum.LOAN_FEE_SPECIAL_CASE_ENUM_AGGREGATE_ADJUSTMENT || specialCase === LoanFeeSpecialCaseEnum.LOAN_FEE_SPECIAL_CASE_ENUM_NONE;
  const [blockNewTotal, setBlockNewTotal] = useState<boolean>(false);
  const [blockTimeframe, setBlockTimeframe] = useState<boolean>(false);

  useEffect(() => {
    if (manualOverrideIndicator) {
      if (!manualOverrideTotalAmount && !manualOverrideTimeframe) {
        setOverrideErrors((prevItems: { [key: string]: boolean }) => ({ ...prevItems, [fee.feeId]: true }));
        setBlockTimeframe(false);
        setBlockNewTotal(false);
      } else {
        if (manualOverrideTotalAmount) {
          setBlockTimeframe(true);
          setBlockNewTotal(false);
        }
        if (manualOverrideTimeframe > 0) {
          setBlockNewTotal(true);
          setBlockTimeframe(false);
        }
        setOverrideErrors(({ [fee.feeId]: _, ...remainingErrors }) => remainingErrors);
      }
    } else {
      setOverrideErrors(({ [fee.feeId]: _, ...remainingErrors }) => remainingErrors);
    }
  }, [fee.feeId, manualOverrideIndicator, manualOverrideTimeframe, manualOverrideTotalAmount, setOverrideErrors]);

  const handleOverrideToggle = useCallback((value: boolean) => {
    if (handleIndicatorOverride) {
      handleIndicatorOverride(value, 'manualOverrideIndicator', fee);
    }
  }, [fee, handleIndicatorOverride]);

  const handleNewTotal = (value: string) => {
    if (handleTotalOverride) {
      handleTotalOverride(value, 'manualOverrideTotalAmount', fee, feePermissions);
      setBlockTimeframe(true);
    }
  };

  const handleTimeframeOnSelect = (data: IValues) => {
    if (handleTimeframeOverride) {
      handleTimeframeOverride(data?.value, 'manualOverrideTimeframe', fee);
      setBlockNewTotal(true);
    }
  };

  const handleClearNewTotal = () => {
    if (handleTotalOverride) {
      handleClear?.();
      setBlockTimeframe(false);
    }
  };

  const handleClearTimeframe = () => {
    if (handleTimeframeOverride) {
      handleClear?.();
      setBlockNewTotal(false);
    }
  };

  const renderNewTotal = () => {
    const viewComponentRules = true;

    if (viewComponentRules) {
      const value = manualOverrideTotalAmount ? convertProtoToCurrency(manualOverrideTotalAmount) : undefined;

      return (
        <div className="flex justify-between items-center pt-[20px] pb-3 px-4 relative">
          <Typography className="text-on-surface-active" variant="body2">New total</Typography>
          {viewOnly ? (
            <Typography className="text-on-surface-active" variant="body2Bold">{manualOverrideTotalAmount ? convertProtoToCurrency(manualOverrideTotalAmount) : '--' }</Typography>
          ) : (
            <>
              <div className="w-32">
                <Input
                  disabled={blockNewTotal}
                  displayOnly
                  id={uniqueKey}
                  mask={MaskType.CURRENCY}
                  onBlur={(e) => handleNewTotal(e.target.value)}
                  placeholder={manualOverrideTotalAmount ? '' : '--'}
                  prefix="$"
                  value={value}
                />
              </div>
              {
                value && (
                  <div className="absolute right-[-24px]">
                    <Tooltip message="Clear new total value">
                      <Icon className="hover:cursor-pointer" icon="Clear" onClick={() => handleClearNewTotal()} />
                    </Tooltip>
                  </div>
                )
              }
            </>
          )}
        </div>
      );
    }
    return null;
  };

  const renderCalculatedTotal = () => {
    const calculatedTotal = calculatedTotalAmount ? convertProtoToCurrency(calculatedTotalAmount) : '--';
    return (
      <div className="flex justify-between items-center pt-[20px] pb-3 px-4">
        <Typography className="text-on-surface-active" variant="body2">Calculated total</Typography>
        <Typography className="text-on-surface-active" variant="body2Bold">{calculatedTotal}</Typography>
      </div>
    );
  };

  const renderPartyDetails = () => {
    const paymentDetails = 'prepaidPayment' in fee
      ? fee.prepaidPayment
      : (fee as LoanEscrowFee.AsObject).escrowPayment;

    return `to ${paymentDetails?.payeePartySummary?.fullName || fee.paidTo}`;
  };

  const renderCalculatedTimeframe = () => {
    const viewComponentRules = specialCase !== LoanFeeSpecialCaseEnum.LOAN_FEE_SPECIAL_CASE_ENUM_AGGREGATE_ADJUSTMENT;
    const caseTypeValues = specialCaseTypeMonths ? pluralizeMonths(Number(calculatedTimeframe)) : pluralizeDays(Number(calculatedTimeframe));
    const calculatedTime = calculatedTimeframe !== '0' ? caseTypeValues : '--';

    if (viewComponentRules) {
      return (
        <div className="flex justify-between items-center pt-[20px] pb-3 px-4">
          <Typography className="text-on-surface-active" variant="body2">Calculated timeframe</Typography>
          <Typography
            className="text-on-surface-active"
            variant="body2Bold"
          >
            {calculatedTime}
          </Typography>
        </div>
      );
    }
    return null;
  };

  const renderManualOverrideTimeframe = () => {
    const viewComponentRules = specialCase === LoanFeeSpecialCaseEnum.LOAN_FEE_SPECIAL_CASE_ENUM_NONE;
    const value = manualOverrideTimeframe && manualOverrideTimeframe > 0 ? manualOverrideTimeframe.toString() : undefined;

    if (viewComponentRules) {
      return (
        <div className="flex justify-between items-center pt-[20px] pb-3 px-4 relative">
          <Typography className="text-on-surface-active" variant="body2">New timeframe</Typography>
          {
            viewOnly ? (
              <Typography className="text-on-surface-active" variant="body2Bold">{manualOverrideTimeframe && manualOverrideTimeframe > 0 ? manualOverrideTimeframe.toString() : '--'}</Typography>
            ) : (
              <>
                <div className="w-32">
                  <Select
                    disabled={blockTimeframe}
                    displayOnly
                    fullWidth={false}
                    id={uniqueKey}
                    onSelect={handleTimeframeOnSelect}
                    options={monthsArray}
                    readonly
                    scrollIntoView={false}
                    titleKey="label"
                    value={value}
                    variant="default"
                  />
                </div>
                {
                  value && (
                    <div className="absolute right-[-24px]">
                      <Tooltip message="Clear new timeframe value">
                        <Icon className="hover:cursor-pointer" icon="Clear" onClick={() => handleClearTimeframe()} />
                      </Tooltip>
                    </div>
                  )
                }
              </>
            )
          }
        </div>
      );
    }
    return null;
  };

  const renderManualOverridePerDiem = () => {
    const viewComponentRules = specialCase === LoanFeeSpecialCaseEnum.LOAN_FEE_SPECIAL_CASE_ENUM_PREPAID_INTEREST;
    const value = manualOverrideTimeframe && manualOverrideTimeframe > 0 ? manualOverrideTimeframe.toString() : undefined;

    if (viewComponentRules) {
      return (
        <div className="flex justify-between items-center pt-[20px] pb-3 px-4 relative">
          <Typography className="text-on-surface-active" variant="body2">New timeframe</Typography>
          {
            viewOnly ? (
              <Typography className="text-on-surface-active" variant="body2Bold">{manualOverrideTimeframe ? manualOverrideTimeframe.toString() : '--'}</Typography>
            ) : (
              <>
                <div className="w-32">
                  <Select
                    disabled={blockTimeframe}
                    displayOnly
                    fullWidth={false}
                    id={uniqueKey}
                    onSelect={handleTimeframeOnSelect}
                    options={daysArray}
                    readonly
                    titleKey="label"
                    value={value}
                    variant="default"
                  />
                </div>
                {
                  value && (
                    <div className="absolute right-[-24px]">
                      <Tooltip message="Clear new timeframe value">
                        <Icon className="hover:cursor-pointer" icon="Clear" onClick={() => handleClearTimeframe()} />
                      </Tooltip>
                    </div>
                  )
                }
              </>
            )
          }
        </div>
      );
    }
    return null;
  };

  return (
    <Disclosure key={id} defaultOpen={manualOverrideIndicator}>
      {({ open }) => (
        <>
          <Disclosure.Button
            as="div"
            className="flex items-center gap-x-4"
          >
            <Icon
              className="h-5 w-5"
              icon={open ? 'ArrowDropDown' : 'ArrowRight'}
            />
            {viewOnly ? (
              <div className="flex align-center justify-left">
                <div className="grid gap-1 mr-3 text-left">
                  <p
                    className="text-sm font-normal text-on-surface-active break-words "
                  >
                    {name}
                  </p>
                  <span className="text-xs font-normal text-on-surface-inactive">
                    {renderPartyDetails()}
                  </span>
                </div>
                {
                  isApr && (
                    <Badge
                      label="apr"
                      variant={UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_SUCCESS}
                    />
                  )
                }
              </div>
            ) : (
              <div className="flex gap-3 items-center justify-start relative">
                <div className="flex-shrink-0">
                  <Button
                    label={fee.name}
                    noCaps
                    onClick={() => onEditFee && onEditFee(fee, categoryType)}
                    textVariant="body2"
                    type="button"
                    variant="tertiary"
                  />
                  <Typography className="text-on-surface-inactive px-2 text-left max-w-[300px] break-words" variant="caption">
                    {renderPartyDetails()}
                  </Typography>
                </div>
                {
                  fee.isApr && (
                    <Badge
                      label="apr"
                      variant={UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_SUCCESS}
                    />
                  )
                }
              </div>
            )}
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
            <div className="pt-[20px] pb-3 px-4 relative">
              <div className="flex justify-between items-center">
                <Typography className="text-on-surface-active mr-3" variant="body2">Manually override fee</Typography>
                {
                  viewOnly ? <Typography className="text-on-surface-active" variant="body2Bold">{manualOverrideIndicator ? 'Yes' : 'No'}</Typography>
                    : <Toggle disabled={viewOnly} onClick={() => handleOverrideToggle(!manualOverrideIndicator)} value={manualOverrideIndicator} />
                }
              </div>
              {
                fee.feeId in overrideErrors && (
                  <div className="absolute right-[-24px] top-4">
                    <Tooltip message="Override values can&apos;t be empty">
                      <Icon className="!text-danger" icon="Error" />
                    </Tooltip>
                  </div>
                )
              }
            </div>
            {manualOverrideIndicator && (
              <>
                {renderCalculatedTotal()}
                {renderCalculatedTimeframe()}
                {renderManualOverrideTimeframe()}
                {renderManualOverridePerDiem()}
                {renderNewTotal()}
              </>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default FeeOverrideControls;
