// source: Wilqo.API.Mortgage.Integration/Queries.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb = require('../Wilqo.API.Mortgage.DynamicData/LoanPage/LoanPage_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb);
var Wilqo_API_Mortgage_Integration_Models_pb = require('../Wilqo.API.Mortgage.Integration/Models_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_Integration_Models_pb);
var Wilqo_API_Mortgage_Integration_Commands_pb = require('../Wilqo.API.Mortgage.Integration/Commands_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_Integration_Commands_pb);
var Wilqo_Shared_Models_ActivityModels_pb = require('../Wilqo.Shared.Models/ActivityModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityModels_pb);
var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('../Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_Mortgage_Integration_models_pb = require('../Wilqo.Shared.Mortgage.Integration/models_pb.js');
goog.object.extend(proto, Wilqo_Shared_Mortgage_Integration_models_pb);
var Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb = require('../Wilqo.Shared.Mortgage.Integration/Pricing/Models_pb.js');
goog.object.extend(proto, Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb);
var Wilqo_Shared_Mortgage_Integration_Documents_Models_pb = require('../Wilqo.Shared.Mortgage.Integration/Documents/Models_pb.js');
goog.object.extend(proto, Wilqo_Shared_Mortgage_Integration_Documents_Models_pb);
var Wilqo_Shared_Mortgage_Integration_MortgageInsurance_Models_pb = require('../Wilqo.Shared.Mortgage.Integration/MortgageInsurance/Models_pb.js');
goog.object.extend(proto, Wilqo_Shared_Mortgage_Integration_MortgageInsurance_Models_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.DisclosurePage', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.Vendor', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.SortOrder', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.ProcessStatus', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.PricingAction', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.displayName = 'proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.displayName = 'proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.displayName = 'proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest.displayName = 'proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse.displayName = 'proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sortOrder: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.SortOrder} */ (reader.readEnum());
      msg.setSortOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSortOrder();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.SortOrder = {
  SORT_ORDER_UNKNOWN: 0,
  SORT_ORDER_NEWEST_FIRST: 1,
  SORT_ORDER_OLDEST_FIRST: 2
};

/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SortOrder sort_order = 3;
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.SortOrder}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.prototype.getSortOrder = function() {
  return /** @type {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.SortOrder} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.SortOrder} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest.prototype.setSortOrder = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    integrationHistoryList: jspb.Message.toObjectList(msg.getIntegrationHistoryList(),
    Wilqo_API_Mortgage_Integration_Models_pb.IntegrationHistory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Integration_Models_pb.IntegrationHistory;
      reader.readMessage(value,Wilqo_API_Mortgage_Integration_Models_pb.IntegrationHistory.deserializeBinaryFromReader);
      msg.addIntegrationHistory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getIntegrationHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Integration_Models_pb.IntegrationHistory.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated IntegrationHistory integration_history = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_integration.IntegrationHistory>}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.prototype.getIntegrationHistoryList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_integration.IntegrationHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Integration_Models_pb.IntegrationHistory, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_integration.IntegrationHistory>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.prototype.setIntegrationHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.IntegrationHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.IntegrationHistory}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.prototype.addIntegrationHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_integration.IntegrationHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationHistoryQueryResponse.prototype.clearIntegrationHistoryList = function() {
  return this.setIntegrationHistoryList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    instanceId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstanceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string instance_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest.prototype.getInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryRequest.prototype.setInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    instanceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    processId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    processName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    stepsList: jspb.Message.toObjectList(msg.getStepsList(),
    Wilqo_API_Mortgage_Integration_Models_pb.StepInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 7:
      var value = new Wilqo_API_Mortgage_Integration_Models_pb.StepInfo;
      reader.readMessage(value,Wilqo_API_Mortgage_Integration_Models_pb.StepInfo.deserializeBinaryFromReader);
      msg.addSteps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getInstanceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProcessId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProcessName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStepsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      Wilqo_API_Mortgage_Integration_Models_pb.StepInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string instance_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.getInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.setInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string process_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.setProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string process_name = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.getProcessName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.setProcessName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string deal_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated StepInfo steps = 7;
 * @return {!Array<!proto.wilqo.api.mortgage_integration.StepInfo>}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.getStepsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_integration.StepInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Integration_Models_pb.StepInfo, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_integration.StepInfo>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.setStepsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.StepInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.StepInfo}
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.addSteps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.mortgage_integration.StepInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessInformationQueryResponse.prototype.clearStepsList = function() {
  return this.setStepsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    instanceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    stepName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStepName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstanceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStepName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string instance_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.prototype.getInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.prototype.setInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string step_name = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.prototype.getStepName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest.prototype.setStepName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    instanceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    processId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    processName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    step: (f = msg.getStep()) && Wilqo_API_Mortgage_Integration_Models_pb.StepInfo.toObject(includeInstance, f),
    jsonInput: jspb.Message.getFieldWithDefault(msg, 7, ""),
    jsonOutput: jspb.Message.getFieldWithDefault(msg, 8, ""),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastUpdated: (f = msg.getLastUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 6:
      var value = new Wilqo_API_Mortgage_Integration_Models_pb.StepInfo;
      reader.readMessage(value,Wilqo_API_Mortgage_Integration_Models_pb.StepInfo.deserializeBinaryFromReader);
      msg.setStep(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setJsonInput(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setJsonOutput(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getInstanceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProcessId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProcessName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStep();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_API_Mortgage_Integration_Models_pb.StepInfo.serializeBinaryToWriter
    );
  }
  f = message.getJsonInput();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getJsonOutput();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdated();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string instance_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.getInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.setInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string process_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.setProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string process_name = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.getProcessName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.setProcessName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string deal_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional StepInfo step = 6;
 * @return {?proto.wilqo.api.mortgage_integration.StepInfo}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.getStep = function() {
  return /** @type{?proto.wilqo.api.mortgage_integration.StepInfo} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Integration_Models_pb.StepInfo, 6));
};


/**
 * @param {?proto.wilqo.api.mortgage_integration.StepInfo|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.setStep = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.clearStep = function() {
  return this.setStep(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.hasStep = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string json_input = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.getJsonInput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.setJsonInput = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string json_output = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.getJsonOutput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.setJsonOutput = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp created = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp last_updated = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.getLastUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.setLastUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.clearLastUpdated = function() {
  return this.setLastUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetProcessStepInformationQueryResponse.prototype.hasLastUpdated = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    packageType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPackageType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string package_type = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.prototype.getPackageType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.prototype.setPackageType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    panelsList: jspb.Message.toObjectList(msg.getPanelsList(),
    Wilqo_Shared_Models_ActivityModels_pb.Panel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.Panel;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.Panel.deserializeBinaryFromReader);
      msg.addPanels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getPanelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.Panel.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.shared.models.Panel panels = 2;
 * @return {!Array<!proto.wilqo.shared.models.Panel>}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.prototype.getPanelsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.Panel>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.Panel, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.Panel>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.prototype.setPanelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.models.Panel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.Panel}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.prototype.addPanels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.models.Panel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetOptionsForDocutechQueryResponse.prototype.clearPanelsList = function() {
  return this.setPanelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    processId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProcessId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string process_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryRequest.prototype.setProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    processId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loanProductsList: jspb.Message.toObjectList(msg.getLoanProductsList(),
    Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.LoanProduct.toObject, includeInstance),
    summary: (f = msg.getSummary()) && Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.PricingProductSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.LoanProduct;
      reader.readMessage(value,Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.LoanProduct.deserializeBinaryFromReader);
      msg.addLoanProducts(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.PricingProductSummary;
      reader.readMessage(value,Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.PricingProductSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProcessId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoanProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.LoanProduct.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.PricingProductSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string process_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.prototype.setProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated wilqo.shared.mortgage_integration.LoanProduct loan_products = 3;
 * @return {!Array<!proto.wilqo.shared.mortgage_integration.LoanProduct>}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.prototype.getLoanProductsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.mortgage_integration.LoanProduct>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.LoanProduct, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.mortgage_integration.LoanProduct>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.prototype.setLoanProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.LoanProduct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.prototype.addLoanProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.mortgage_integration.LoanProduct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.prototype.clearLoanProductsList = function() {
  return this.setLoanProductsList([]);
};


/**
 * optional wilqo.shared.mortgage_integration.PricingProductSummary summary = 4;
 * @return {?proto.wilqo.shared.mortgage_integration.PricingProductSummary}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.prototype.getSummary = function() {
  return /** @type{?proto.wilqo.shared.mortgage_integration.PricingProductSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.PricingProductSummary, 4));
};


/**
 * @param {?proto.wilqo.shared.mortgage_integration.PricingProductSummary|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetProductSearchResultsQueryResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    processId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProcessId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string process_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryRequest.prototype.setProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    processId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productPricingDetails: (f = msg.getProductPricingDetails()) && Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.ProductPricingDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.ProductPricingDetail;
      reader.readMessage(value,Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.ProductPricingDetail.deserializeBinaryFromReader);
      msg.setProductPricingDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProcessId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductPricingDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.ProductPricingDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string process_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.prototype.setProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.mortgage_integration.ProductPricingDetail product_pricing_details = 3;
 * @return {?proto.wilqo.shared.mortgage_integration.ProductPricingDetail}
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.prototype.getProductPricingDetails = function() {
  return /** @type{?proto.wilqo.shared.mortgage_integration.ProductPricingDetail} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.ProductPricingDetail, 3));
};


/**
 * @param {?proto.wilqo.shared.mortgage_integration.ProductPricingDetail|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.prototype.setProductPricingDetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.prototype.clearProductPricingDetails = function() {
  return this.setProductPricingDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetProductDetailsResultsQueryResponse.prototype.hasProductPricingDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    vendor: jspb.Message.getFieldWithDefault(msg, 5, 0),
    customValuesList: jspb.Message.toObjectList(msg.getCustomValuesList(),
    Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.CustomValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.ProductSearchVendorType} */ (reader.readEnum());
      msg.setVendor(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.CustomValue;
      reader.readMessage(value,Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.CustomValue.deserializeBinaryFromReader);
      msg.addCustomValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVendor();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCustomValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.CustomValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string product_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional wilqo.shared.mortgage_integration.ProductSearchVendorType vendor = 5;
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchVendorType}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.prototype.getVendor = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.ProductSearchVendorType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.ProductSearchVendorType} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.prototype.setVendor = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated wilqo.shared.mortgage_integration.CustomValue custom_values = 6;
 * @return {!Array<!proto.wilqo.shared.mortgage_integration.CustomValue>}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.prototype.getCustomValuesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.mortgage_integration.CustomValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.CustomValue, 6));
};


/**
 * @param {!Array<!proto.wilqo.shared.mortgage_integration.CustomValue>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest} returns this
*/
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.prototype.setCustomValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.CustomValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.mortgage_integration.CustomValue}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.prototype.addCustomValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.shared.mortgage_integration.CustomValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryRequest.prototype.clearCustomValuesList = function() {
  return this.setCustomValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    ineligibleReasonsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addIneligibleReasons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getIneligibleReasonsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string ineligible_reasons = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.prototype.getIneligibleReasonsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.prototype.setIneligibleReasonsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.prototype.addIneligibleReasons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductIneligibleReasonsQueryResponse.prototype.clearIneligibleReasonsList = function() {
  return this.setIneligibleReasonsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    vendorsList: jspb.Message.toObjectList(msg.getVendorsList(),
    Wilqo_API_Mortgage_Integration_Models_pb.SimpleOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Integration_Models_pb.SimpleOption;
      reader.readMessage(value,Wilqo_API_Mortgage_Integration_Models_pb.SimpleOption.deserializeBinaryFromReader);
      msg.addVendors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getVendorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Integration_Models_pb.SimpleOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SimpleOption vendors = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_integration.SimpleOption>}
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.prototype.getVendorsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_integration.SimpleOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Integration_Models_pb.SimpleOption, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_integration.SimpleOption>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.prototype.setVendorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.SimpleOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.SimpleOption}
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.prototype.addVendors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_integration.SimpleOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetDocumentVendorListQueryResponse.prototype.clearVendorsList = function() {
  return this.setVendorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    requestingFor: jspb.Message.getFieldWithDefault(msg, 4, 0),
    vendor: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.DisclosurePage} */ (reader.readEnum());
      msg.setRequestingFor(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.Vendor} */ (reader.readEnum());
      msg.setVendor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRequestingFor();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getVendor();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.DisclosurePage = {
  DISCLOSURE_PAGE_UNKNOWN: 0,
  DISCLOSURE_PAGE_TRID: 1,
  DISCLOSURE_PAGE_CLOSING: 2,
  DISCLOSURE_PAGE_DISCLOSURE: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.Vendor = {
  VENDOR_UNKNOWN: 0,
  VENDOR_DOCUTECH: 1,
  VENDOR_ASURITY: 2,
  VENDOR_DOC_MAGIC: 3
};

/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DisclosurePage requesting_for = 4;
 * @return {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.DisclosurePage}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.prototype.getRequestingFor = function() {
  return /** @type {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.DisclosurePage} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.DisclosurePage} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.prototype.setRequestingFor = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional Vendor vendor = 5;
 * @return {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.Vendor}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.prototype.getVendor = function() {
  return /** @type {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.Vendor} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.Vendor} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryRequest.prototype.setVendor = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    disclosureTypesList: jspb.Message.toObjectList(msg.getDisclosureTypesList(),
    Wilqo_API_Mortgage_Integration_Models_pb.SimpleOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Integration_Models_pb.SimpleOption;
      reader.readMessage(value,Wilqo_API_Mortgage_Integration_Models_pb.SimpleOption.deserializeBinaryFromReader);
      msg.addDisclosureTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDisclosureTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Integration_Models_pb.SimpleOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SimpleOption disclosure_types = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_integration.SimpleOption>}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.prototype.getDisclosureTypesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_integration.SimpleOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Integration_Models_pb.SimpleOption, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_integration.SimpleOption>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.prototype.setDisclosureTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.SimpleOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.SimpleOption}
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.prototype.addDisclosureTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_integration.SimpleOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetDisclosureTypesQueryResponse.prototype.clearDisclosureTypesList = function() {
  return this.setDisclosureTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    processInstanceId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProcessInstanceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string process_instance_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest.prototype.getProcessInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryRequest.prototype.setProcessInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    referenceLink: (f = msg.getReferenceLink()) && Wilqo_Shared_Mortgage_Integration_Documents_Models_pb.ReferenceLink.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Mortgage_Integration_Documents_Models_pb.ReferenceLink;
      reader.readMessage(value,Wilqo_Shared_Mortgage_Integration_Documents_Models_pb.ReferenceLink.deserializeBinaryFromReader);
      msg.setReferenceLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getReferenceLink();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Mortgage_Integration_Documents_Models_pb.ReferenceLink.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.mortgage_integration.ReferenceLink reference_link = 2;
 * @return {?proto.wilqo.shared.mortgage_integration.ReferenceLink}
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.prototype.getReferenceLink = function() {
  return /** @type{?proto.wilqo.shared.mortgage_integration.ReferenceLink} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Mortgage_Integration_Documents_Models_pb.ReferenceLink, 2));
};


/**
 * @param {?proto.wilqo.shared.mortgage_integration.ReferenceLink|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.prototype.setReferenceLink = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.prototype.clearReferenceLink = function() {
  return this.setReferenceLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetAsurityDocumentResultQueryResponse.prototype.hasReferenceLink = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    creditRequestType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.CreditRequestType} */ (reader.readEnum());
      msg.setCreditRequestType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreditRequestType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional RunCreditCommandRequest.CreditRequestType credit_request_type = 4;
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.CreditRequestType}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.prototype.getCreditRequestType = function() {
  return /** @type {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.CreditRequestType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.CreditRequestType} value
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryRequest.prototype.setCreditRequestType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    panelsList: jspb.Message.toObjectList(msg.getPanelsList(),
    Wilqo_Shared_Models_ActivityModels_pb.Panel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.Panel;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.Panel.deserializeBinaryFromReader);
      msg.addPanels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getPanelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.Panel.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated wilqo.shared.models.Panel panels = 3;
 * @return {!Array<!proto.wilqo.shared.models.Panel>}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.prototype.getPanelsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.Panel>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.Panel, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.Panel>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.prototype.setPanelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.Panel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.Panel}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.prototype.addPanels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.Panel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetOptionsForCreditQueryResponse.prototype.clearPanelsList = function() {
  return this.setPanelsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    processTypesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.wilqo.shared.mortgage_integration.ProcessType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProcessTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProcessTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated wilqo.shared.mortgage_integration.ProcessType process_types = 4;
 * @return {!Array<!proto.wilqo.shared.mortgage_integration.ProcessType>}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.prototype.getProcessTypesList = function() {
  return /** @type {!Array<!proto.wilqo.shared.mortgage_integration.ProcessType>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.wilqo.shared.mortgage_integration.ProcessType>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.prototype.setProcessTypesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.ProcessType} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.prototype.addProcessTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryRequest.prototype.clearProcessTypesList = function() {
  return this.setProcessTypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    processesList: jspb.Message.toObjectList(msg.getProcessesList(),
    proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary;
      reader.readMessage(value,proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.deserializeBinaryFromReader);
      msg.addProcesses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProcessesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    instanceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startedOn: (f = msg.getStartedOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastUpdated: (f = msg.getLastUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    processType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary;
  return proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartedOn(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdated(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.ProcessType} */ (reader.readEnum());
      msg.setProcessType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstanceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartedOn();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProcessType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string instance_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.prototype.getInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary} returns this
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.prototype.setInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp started_on = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.prototype.getStartedOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary} returns this
*/
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.prototype.setStartedOn = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary} returns this
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.prototype.clearStartedOn = function() {
  return this.setStartedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.prototype.hasStartedOn = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp last_updated = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.prototype.getLastUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary} returns this
*/
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.prototype.setLastUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary} returns this
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.prototype.clearLastUpdated = function() {
  return this.setLastUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.prototype.hasLastUpdated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.mortgage_integration.ProcessType process_type = 4;
 * @return {!proto.wilqo.shared.mortgage_integration.ProcessType}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.prototype.getProcessType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.ProcessType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.ProcessType} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary} returns this
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary.prototype.setProcessType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DealProcessSummary processes = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary>}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.prototype.getProcessesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.prototype.setProcessesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary}
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.prototype.addProcesses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.DealProcessSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetDealProcessesQueryResponse.prototype.clearProcessesList = function() {
  return this.setProcessesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string track_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    requestType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    requestObject: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestObject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getRequestType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequestObject();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string request_type = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.prototype.getRequestType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.prototype.setRequestType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string request_object = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.prototype.getRequestObject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryResponse.prototype.setRequestObject = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string track_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    responseType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    responseObject: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponseType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponseObject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getResponseType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResponseObject();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string response_type = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.prototype.getResponseType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.prototype.setResponseType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string response_object = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.prototype.getResponseObject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryResponse.prototype.setResponseObject = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string track_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    hasreport: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    documentType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documentUrl: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasreport(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getHasreport();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDocumentType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumentUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool hasReport = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.prototype.getHasreport = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.prototype.setHasreport = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string document_type = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.prototype.getDocumentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.prototype.setDocumentType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string document_url = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.prototype.getDocumentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetIntegrationReportQueryResponse.prototype.setDocumentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    loanPagesList: jspb.Message.toObjectList(msg.getLoanPagesList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.toObject, includeInstance),
    operationsList: jspb.Message.toObjectList(msg.getOperationsList(),
    proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.toObject, includeInstance),
    isRateLockPresentInDeal: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.deserializeBinaryFromReader);
      msg.addLoanPages(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation;
      reader.readMessage(value,proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.deserializeBinaryFromReader);
      msg.addOperations(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRateLockPresentInDeal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLoanPagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.serializeBinaryToWriter
    );
  }
  f = message.getOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.serializeBinaryToWriter
    );
  }
  f = message.getIsRateLockPresentInDeal();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.toObject = function(includeInstance, msg) {
  var f, obj = {
    vendor: jspb.Message.getFieldWithDefault(msg, 1, 0),
    availableActionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation;
  return proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.ProductSearchVendorType} */ (reader.readEnum());
      msg.setVendor(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.PricingAction>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAvailableActions(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVendor();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAvailableActionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.PricingAction = {
  PRICING_ACTION_NONE: 0,
  PRICING_ACTION_SELECT_RATE: 1,
  PRICING_ACTION_LOCK_RATE: 2
};

/**
 * optional wilqo.shared.mortgage_integration.ProductSearchVendorType vendor = 1;
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchVendorType}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.prototype.getVendor = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.ProductSearchVendorType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.ProductSearchVendorType} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.prototype.setVendor = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated PricingAction available_actions = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.PricingAction>}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.prototype.getAvailableActionsList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.PricingAction>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.PricingAction>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.prototype.setAvailableActionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.PricingAction} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.prototype.addAvailableActions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation.prototype.clearAvailableActionsList = function() {
  return this.setAvailableActionsList([]);
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.api.mortgage_dynamic_data.LoanPage loan_pages = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPage>}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.prototype.getLoanPagesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPage>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPage>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.prototype.setLoanPagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPage}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.prototype.addLoanPages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.LoanPage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.prototype.clearLoanPagesList = function() {
  return this.setLoanPagesList([]);
};


/**
 * repeated PricingOperation operations = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation>}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.prototype.getOperationsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.prototype.setOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.prototype.addOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.PricingOperation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.prototype.clearOperationsList = function() {
  return this.setOperationsList([]);
};


/**
 * optional bool is_rate_lock_present_in_deal = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.prototype.getIsRateLockPresentInDeal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetProductOptionParametersQueryResponse.prototype.setIsRateLockPresentInDeal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    integrationType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    integrationVendor: jspb.Message.getFieldWithDefault(msg, 3, 0),
    additionalInformation: (f = msg.getAdditionalInformation()) && google_protobuf_any_pb.Any.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    processType: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage_integration.IntegrationTypeLocalEnum} */ (reader.readEnum());
      msg.setIntegrationType(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage_integration.IntegrationVendorEnum} */ (reader.readEnum());
      msg.setIntegrationVendor(value);
      break;
    case 4:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setAdditionalInformation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.ProcessType} */ (reader.readEnum());
      msg.setProcessType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntegrationType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getIntegrationVendor();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAdditionalInformation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProcessType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IntegrationTypeLocalEnum integration_type = 2;
 * @return {!proto.wilqo.api.mortgage_integration.IntegrationTypeLocalEnum}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.prototype.getIntegrationType = function() {
  return /** @type {!proto.wilqo.api.mortgage_integration.IntegrationTypeLocalEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.IntegrationTypeLocalEnum} value
 * @return {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.prototype.setIntegrationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional IntegrationVendorEnum integration_vendor = 3;
 * @return {!proto.wilqo.api.mortgage_integration.IntegrationVendorEnum}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.prototype.getIntegrationVendor = function() {
  return /** @type {!proto.wilqo.api.mortgage_integration.IntegrationVendorEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.IntegrationVendorEnum} value
 * @return {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.prototype.setIntegrationVendor = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Any additional_information = 4;
 * @return {?proto.google.protobuf.Any}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.prototype.getAdditionalInformation = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 4));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest} returns this
*/
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.prototype.setAdditionalInformation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.prototype.clearAdditionalInformation = function() {
  return this.setAdditionalInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.prototype.hasAdditionalInformation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional wilqo.shared.mortgage_integration.ProcessType process_type = 6;
 * @return {!proto.wilqo.shared.mortgage_integration.ProcessType}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.prototype.getProcessType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.ProcessType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.ProcessType} value
 * @return {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest.prototype.setProcessType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    isValid: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    requiredFieldsPage: (f = msg.getRequiredFieldsPage()) && Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsValid(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.deserializeBinaryFromReader);
      msg.setRequiredFieldsPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getIsValid();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRequiredFieldsPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_valid = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.prototype.getIsValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.prototype.setIsValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional wilqo.api.mortgage_dynamic_data.LoanPage required_fields_page = 3;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.LoanPage}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.prototype.getRequiredFieldsPage = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.LoanPage} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.LoanPage|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.prototype.setRequiredFieldsPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.prototype.clearRequiredFieldsPage = function() {
  return this.setRequiredFieldsPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryResponse.prototype.hasRequiredFieldsPage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    requestTypesList: jspb.Message.toObjectList(msg.getRequestTypesList(),
    Wilqo_API_Mortgage_Integration_Models_pb.SimpleOption.toObject, includeInstance),
    defaultSelection: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Integration_Models_pb.SimpleOption;
      reader.readMessage(value,Wilqo_API_Mortgage_Integration_Models_pb.SimpleOption.deserializeBinaryFromReader);
      msg.addRequestTypes(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultSelection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getRequestTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Integration_Models_pb.SimpleOption.serializeBinaryToWriter
    );
  }
  f = message.getDefaultSelection();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SimpleOption request_types = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_integration.SimpleOption>}
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.prototype.getRequestTypesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_integration.SimpleOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Integration_Models_pb.SimpleOption, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_integration.SimpleOption>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.prototype.setRequestTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.SimpleOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.SimpleOption}
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.prototype.addRequestTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_integration.SimpleOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.prototype.clearRequestTypesList = function() {
  return this.setRequestTypesList([]);
};


/**
 * optional string default_selection = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.prototype.getDefaultSelection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetDocMagicRequestTypesQueryResponse.prototype.setDefaultSelection = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    integrationType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage_integration.IntegrationTypeLocalEnum} */ (reader.readEnum());
      msg.setIntegrationType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntegrationType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional IntegrationTypeLocalEnum integration_type = 2;
 * @return {!proto.wilqo.api.mortgage_integration.IntegrationTypeLocalEnum}
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest.prototype.getIntegrationType = function() {
  return /** @type {!proto.wilqo.api.mortgage_integration.IntegrationTypeLocalEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.IntegrationTypeLocalEnum} value
 * @return {!proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryRequest.prototype.setIntegrationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    readyToGo: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    reason: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadyToGo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getReadyToGo();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool ready_to_go = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.prototype.getReadyToGo = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.prototype.setReadyToGo = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string reason = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetPreflightChecklistQueryResponse.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    accountData: (f = msg.getAccountData()) && Wilqo_API_Mortgage_Integration_Models_pb.AccountData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Integration_Models_pb.AccountData;
      reader.readMessage(value,Wilqo_API_Mortgage_Integration_Models_pb.AccountData.deserializeBinaryFromReader);
      msg.setAccountData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getAccountData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_Integration_Models_pb.AccountData.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AccountData account_data = 2;
 * @return {?proto.wilqo.api.mortgage_integration.AccountData}
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.prototype.getAccountData = function() {
  return /** @type{?proto.wilqo.api.mortgage_integration.AccountData} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Integration_Models_pb.AccountData, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_integration.AccountData|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.prototype.setAccountData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.prototype.clearAccountData = function() {
  return this.setAccountData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetAccountDetailsQueryResponse.prototype.hasAccountData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    loanPagesList: jspb.Message.toObjectList(msg.getLoanPagesList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.deserializeBinaryFromReader);
      msg.addLoanPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLoanPagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.api.mortgage_dynamic_data.LoanPage loan_pages = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPage>}
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.prototype.getLoanPagesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPage>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPage>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.prototype.setLoanPagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPage}
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.prototype.addLoanPages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.LoanPage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetFloodOptionsQueryResponse.prototype.clearLoanPagesList = function() {
  return this.setLoanPagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    processId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProcessId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string process_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryRequest.prototype.setProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    processId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    quotesList: jspb.Message.toObjectList(msg.getQuotesList(),
    Wilqo_Shared_Mortgage_Integration_MortgageInsurance_Models_pb.MortgageInsuranceQuote.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Mortgage_Integration_MortgageInsurance_Models_pb.MortgageInsuranceQuote;
      reader.readMessage(value,Wilqo_Shared_Mortgage_Integration_MortgageInsurance_Models_pb.MortgageInsuranceQuote.deserializeBinaryFromReader);
      msg.addQuotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProcessId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_Shared_Mortgage_Integration_MortgageInsurance_Models_pb.MortgageInsuranceQuote.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string process_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.prototype.setProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated wilqo.shared.mortgage_integration.MortgageInsuranceQuote quotes = 3;
 * @return {!Array<!proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote>}
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.prototype.getQuotesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Mortgage_Integration_MortgageInsurance_Models_pb.MortgageInsuranceQuote, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.prototype.setQuotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote}
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.prototype.addQuotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetMortgageInsuraceQuotesSearchResultsQueryResponse.prototype.clearQuotesList = function() {
  return this.setQuotesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    panel: (f = msg.getPanel()) && Wilqo_Shared_Models_ActivityModels_pb.Panel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.Panel;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.Panel.deserializeBinaryFromReader);
      msg.setPanel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getPanel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.Panel.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.Panel panel = 2;
 * @return {?proto.wilqo.shared.models.Panel}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.prototype.getPanel = function() {
  return /** @type{?proto.wilqo.shared.models.Panel} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.Panel, 2));
};


/**
 * @param {?proto.wilqo.shared.models.Panel|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.prototype.setPanel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.prototype.clearPanel = function() {
  return this.setPanel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetOptionsForMortgageInsuranceQuotesQueryResponse.prototype.hasPanel = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    processTypesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    subscribeToLiveUpdates: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest;
  return proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.wilqo.shared.mortgage_integration.ProcessType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProcessTypes(values[i]);
      }
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscribeToLiveUpdates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProcessTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getSubscribeToLiveUpdates();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated wilqo.shared.mortgage_integration.ProcessType process_types = 3;
 * @return {!Array<!proto.wilqo.shared.mortgage_integration.ProcessType>}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.prototype.getProcessTypesList = function() {
  return /** @type {!Array<!proto.wilqo.shared.mortgage_integration.ProcessType>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.mortgage_integration.ProcessType>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.prototype.setProcessTypesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.ProcessType} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.prototype.addProcessTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.prototype.clearProcessTypesList = function() {
  return this.setProcessTypesList([]);
};


/**
 * optional bool subscribe_to_live_updates = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.prototype.getSubscribeToLiveUpdates = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryRequest.prototype.setSubscribeToLiveUpdates = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    runningProcessesList: jspb.Message.toObjectList(msg.getRunningProcessesList(),
    proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse;
  return proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess;
      reader.readMessage(value,proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.deserializeBinaryFromReader);
      msg.addRunningProcesses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRunningProcessesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.ProcessStatus = {
  PROCESS_STATUS_UNKNOWN: 0,
  PROCESS_STATUS_READY: 1,
  PROCESS_STATUS_RUNNING: 2,
  PROCESS_STATUS_PAUSED: 3,
  PROCESS_STATUS_FAILED: 4,
  PROCESS_STATUS_COMPLETED: 5
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.toObject = function(includeInstance, msg) {
  var f, obj = {
    instanceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startedOn: (f = msg.getStartedOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastUpdated: (f = msg.getLastUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    processType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess;
  return proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstanceId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartedOn(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdated(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.ProcessType} */ (reader.readEnum());
      msg.setProcessType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.ProcessStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstanceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartedOn();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProcessType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string instance_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.getInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess} returns this
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.setInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp started_on = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.getStartedOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess} returns this
*/
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.setStartedOn = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess} returns this
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.clearStartedOn = function() {
  return this.setStartedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.hasStartedOn = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp last_updated = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.getLastUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess} returns this
*/
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.setLastUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess} returns this
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.clearLastUpdated = function() {
  return this.setLastUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.hasLastUpdated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.mortgage_integration.ProcessType process_type = 4;
 * @return {!proto.wilqo.shared.mortgage_integration.ProcessType}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.getProcessType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.ProcessType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.ProcessType} value
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess} returns this
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.setProcessType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string display_name = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess} returns this
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ProcessStatus status = 6;
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.ProcessStatus}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.ProcessStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.ProcessStatus} value
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess} returns this
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated RunningProcess running_processes = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess>}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.prototype.getRunningProcessesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess>} value
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.prototype.setRunningProcessesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess}
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.prototype.addRunningProcesses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.RunningProcess, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.GetLiveProcessesQueryResponse.prototype.clearRunningProcessesList = function() {
  return this.setRunningProcessesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest}
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest;
  return proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest}
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse}
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse;
  return proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse}
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse} returns this
*/
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.UnsubscribeFromLiveProcessesUpdatesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.wilqo.api.mortgage_integration);
